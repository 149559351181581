<template>
  <div class="RunContentLastPage">
    <h1 class="display-4">{{ $t("GENERAL.CONGRATULATIONS") }}!</h1>
    <p class="lead">
      {{ $t(text) }}
    </p>

    <b-container class="bv-example-row">
      <b-row class="justify-content-md-center mt-4 d-flex bg-gray-600 bg-dark" v-if="video !== ''">
        <la-vimeo-player :video-id="video" :show="true" :seconds="0" style="width: 100%"> </la-vimeo-player>
      </b-row>
      <b-row class="mt-10 d-flex w-100 justify-content-center">
        <button @click="clickCertificate" class="btn btn-outline-success mx-4" v-if="showCertificate">
          <i class="fa fa-certificate" v-if="!loadingCertificate"></i>
          <i class="fa fa-spinner fa-spin" v-if="loadingCertificate"></i>
          {{ $t("GENERAL.CERTIFICATE") }}
        </button>

        <button @click="clickReport" v-if="hasReport" class="btn btn-outline-danger btn-hover-light-danger mx-4">
          <i class="fa fa-file-pdf" v-if="!loadingReport"></i>
          <i class="fa fa-spinner fa-spin" v-if="loadingReport"></i>
          {{ $t("GENERAL.DOWNLOAD") }} {{ $t("GENERAL.REPORT") }}
        </button>

        <button @click="showReport = !showReport" v-if="hasReport && !loadingReport" class="btn btn-outline-info btn-hover-light-info mx-4">
          <i class="fa fa-eye" v-if="!showReport"></i>
          <i class="fa fa-eye-slash" v-if="showReport"></i>
          <i class="fa fa-spinner fa-spin" v-if="loadingReport"></i>
          {{ $t("GENERAL.REPORT") }}
        </button>

        <button @click="clickReplay" v-if="showRestart" class="btn btn-outline-primary btn-hover-primary mx-4">
          <i class="fa fa-play"></i>
          {{ $t("GENERAL.REPLAY_CONTENT") }}
        </button>
      </b-row>

      <b-row class="justify-content-md-center mt-4" v-if="hasReport && showReport">
        <iframe :src="objectURL" type="application/pdf" width="100%" height="100%" style="overflow: auto" class="border-0 min-h-800px" v-if="!loadingReport">
        </iframe>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { GET_CERTIFICATE, GET_DOWLOADABLE_REPORT, GET_REPORT } from "@/core/services/store/report.module";
import { mapGetters } from "vuex";
import { GET_RESULT_DATA_CONTENT } from "@/core/services/store/token.module";
import LaVimeoPlayer from "@/view/pages/content-components/LaVimeoPlayer/LaVimeoPlayer";

export default {
  name: "run-content-last-page",
  components: { LaVimeoPlayer },
  props: {
    currentToken: String,
    engine: String,
    saveContent: {
      type: Object,
      required: true,
    },
    contentCodename: {
      type: String,
      required: true,
    },
    text: String,
  },
  data() {
    return {
      loadingCertificate: false,
      loadingReport: false,
      showReport: false,
      hasReport: false,
      hasFreeVersion: false,
      showCertificate: false,
      showRestart: false,
      video: "",
      objectURL: null,
    };
  },
  computed: {
    ...mapGetters({ currentUserFullName: "auth/currentUserFullName" }),
  },
  methods: {
    clickCertificate() {
      if (this.loadingCertificate) {
        return;
      }
      this.loadingCertificate = true;
      this.$store
        .dispatch("report/" + GET_CERTIFICATE, {
          token: this.currentToken,
          filename: this.$laUtils.slugIt("CERTIFICATE " + this.contentCodename + " " + this.currentUserFullName),
        })
        .finally(() => {
          this.loadingCertificate = false;
        });
    },
    clickReplay() {
      this.$emit("replay");
    },
    clickReport() {
      if (this.loadingReport) {
        return;
      }
      const link = document.createElement("a");
      link.href = this.objectURL;
      link.setAttribute("download", this.$laUtils.slugIt("Report " + this.contentCodename + " " + this.currentUserFullName));
      document.body.appendChild(link);
      link.click();
      link.remove();

      // this.loadingReport = true;
      // this.$store
      //   .dispatch("report/" + GET_REPORT, {
      //     token: this.currentToken,
      //     filename: this.$laUtils.slugIt("Report " + this.contentCodename + " " + this.currentUserFullName),
      //   })
      //   .finally(() => {
      //     this.loadingReport = false;
      //   });
    },
  },
  mounted() {
    let self = this;
    self.loadingReport = true;
    this.$store
      .dispatch("token/" + GET_RESULT_DATA_CONTENT, { token: this.currentToken })
      .then(({ data }) => {
        self.showCertificate = data.has_certificate ?? false;
        self.hasFreeVersion = data.has_free_version ?? false;
        self.hasReport = data.has_report ?? false;
        self.showRestart = data.show_restart ?? false;
        self.video = data.video ?? "";
        self.showReport = self.video === "";

        this.$store
          .dispatch("report/" + GET_DOWLOADABLE_REPORT, {
            token: this.currentToken,
            filename: this.$laUtils.slugIt("Report " + this.contentCodename + " " + this.currentUserFullName),
          })
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let URL = window.webkitURL || window.URL;
            self.objectURL = URL.createObjectURL(blob);
          })
          .finally(() => {
            self.loadingReport = false;
          });
      })
      .finally(() => {});

    // console.log(this.saveContent);
  },
};
</script>
