<template>
  <div class="MixedCourseItemPdf p-0">
    <b-row class="justify-content-md-center" v-if="loadingReport">
      <b-col cols="12" md="auto">
        <b-spinner class="text-primary"></b-spinner>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-end mb-2" v-if="!loadingReport">
      <button class="btn btn-success" @click="clickDownload">
        <i class="fa fa-download"></i>
        {{ $t("GENERAL.DOWNLOAD") }}
      </button>
    </div>

    <iframe :src="objectURL" type="application/pdf" width="100%" height="100%" style="overflow: auto" class="border-0 min-h-800px" v-if="!loadingReport">
    </iframe>
  </div>
</template>

<script>
import { GET_DOWLOADABLE_CONTENT } from "@/core/services/store/token.module";

export default {
  name: "mixed-course-item-pdf",
  components: {},
  props: {
    props: {
      itemPreset: {
        type: Object,
        required: true,
      },

      itemSaveData: {
        type: Object,
        required: true,
      },
      contentEngine: {
        type: String,
        required: true,
      },
      currentToken: {
        type: String,
        required: true,
      },
    },
    contentCodename: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingReport: false,
      objectURL: null,
      loading: true,
    };
  },
  methods: {
    clickDownload() {
      const link = document.createElement("a");
      link.href = this.objectURL;
      link.setAttribute("download", this.$attrs["item-preset"]["resource"]);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    clickReport() {
      let self = this;
      if (this.loadingReport || this.objectURL !== null) {
        return;
      }
      this.$emit("save-data", { progress: 100 });
      this.loadingReport = true;
      this.$store
        .dispatch("token/" + GET_DOWLOADABLE_CONTENT, {
          codename: this.contentCodename,
          filename: this.$attrs["item-preset"]["resource"],
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let URL = window.webkitURL || window.URL;
          this.objectURL = URL.createObjectURL(blob);
          this.loadingReport = false;
        })
        .finally(() => {
          // this.loadingReport = false;
        });
    },
  },
  mounted() {
    this.clickReport();
  },
};
</script>
