var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LaRange mt-20 rounded border-l border-blue-400"},[_c('b-row',{staticClass:"mb-0",staticStyle:{"min-height":"58px !important"}},[_c('b-col',{staticClass:"rounded p-2 d-flex w-full mb-0"},[_c('div',{staticClass:"flex float-right"},[_c('i',{staticClass:"fa fa-check fa-2x mx-2",class:{ 'text-success': _vm.answer !== null, 'text-light': _vm.answer == null },attrs:{"id":'question_' + _vm.questionNumber}})]),_c('span',{staticClass:"h6 mt-2 ml-2 flex mb-0"},[_vm._v(_vm._s(_vm.questionNumber)+"- "+_vm._s(_vm.text)+" "+_vm._s(_vm.displayAnswer))])])],1),_c('b-form-group',{staticClass:"d-md-none",scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-form-radio-group',{staticClass:"lead align-center",attrs:{"size":"lg","options":_vm.options,"button-variant":"outline-primary","aria-describedby":ariaDescribedby,"name":'radio' + _vm.questionNumber,"stacked":"","buttons":""},on:{"change":_vm.radioChanged},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})]}}])}),_c('b-row',{staticClass:"mb-4 p-2 rounded text-primary d-none d-md-flex",attrs:{"align-h":"center"}},[_c('b-col',{},[_c('button',{staticClass:"btn btn-sm w-100 text-center p-1 m-2",class:{
          'btn-primary': _vm.answer === 1,
          'btn-outline-primary': _vm.answer !== 1,
        },staticStyle:{"min-height":"40px"},domProps:{"innerHTML":_vm._s(_vm.$t('GENERAL.COMPLETELY_DISAGREE'))},on:{"click":function($event){return _vm.setAnswer(1)}}})]),_c('b-col',{},[_c('button',{staticClass:"btn btn-sm w-100 text-center p-1 m-2",class:{
          'btn-primary': _vm.answer === 25,
          'btn-outline-primary': _vm.answer !== 25,
        },staticStyle:{"min-height":"40px"},domProps:{"innerHTML":_vm._s(_vm.$t('GENERAL.PARTIALLY_DISAGREE'))},on:{"click":function($event){return _vm.setAnswer(25)}}})]),_c('b-col',{},[_c('button',{staticClass:"btn btn-sm w-100 text-center p-1 m-2",class:{
          'btn-primary': _vm.answer === 50,
          'btn-outline-primary': _vm.answer !== 50,
        },staticStyle:{"min-height":"40px"},domProps:{"innerHTML":_vm._s(_vm.$t('GENERAL.NEUTRAL'))},on:{"click":function($event){return _vm.setAnswer(50)}}})]),_c('b-col',{},[_c('button',{staticClass:"btn btn-sm w-100 text-center p-1 m-2",class:{
          'btn-primary': _vm.answer === 75,
          'btn-outline-primary': _vm.answer !== 75,
        },staticStyle:{"min-height":"40px"},domProps:{"innerHTML":_vm._s(_vm.$t('GENERAL.PARTIALLY_AGREE'))},on:{"click":function($event){return _vm.setAnswer(75)}}})]),_c('b-col',{},[_c('button',{staticClass:"btn btn-sm w-100 text-center p-1 m-2",class:{
          'btn-primary': _vm.answer === 100,
          'btn-outline-primary': _vm.answer !== 100,
        },staticStyle:{"min-height":"40px"},domProps:{"innerHTML":_vm._s(_vm.$t('GENERAL.COMPLETELY_AGREE'))},on:{"click":function($event){return _vm.setAnswer(100)}}})])],1),_c('b-row'),_c('hr')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }