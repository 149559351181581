<template>
  <div class="VimeoEngine text-dark" v-if="this.show">
    <b-card
      ref="video-card-body"
      class="shadow-xs text-center"
      header-tag="header"
      footer-tag="footer"
      border-variant="primary"
      header-border-variant="primary"
    >
      <template #header>
        <h3 class="mb-0">
          {{ preset.preset_texts.name }}
        </h3>
      </template>
      <i v-if="page === ''" class="fa fa-spinner fa-2x text-primary fa-spin"></i>
      <div v-if="page === 'video'">
        <p class="lead text-left" v-html="preset.preset_texts.subtitle"></p>
        <la-vimeo-player :video-id="videoId" :show="page === 'video'" :seconds="seconds" @time-tick="onTimeTick" @end-tick="onTimeTick"> </la-vimeo-player>
      </div>

      <div v-if="page === 'final'">
        <run-content-last-page
          :engine="saveContent.engine"
          :save-content="saveContent"
          :currentToken="currentToken"
          :content-codename="preset.codename"
          text="GENERAL.CONTENT_LAST_PAGE_VIDEO"
          @replay="page = 'video'"
        ></run-content-last-page>
      </div>

      <template #footer v-if="saveContent.progress >= 99 && page !== ''">
        <b-button variant="primary" @click="page = 'final'" v-if="page !== 'final'" class="float-right">{{ $t("GENERAL.CERTIFICATE") }} </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";
import LaVimeoPlayer from "@/view/pages/content-components/LaVimeoPlayer/LaVimeoPlayer";
export default {
  name: "vimeo-engine",

  components: { LaVimeoPlayer, RunContentLastPage },
  props: {
    preset: Object,
    saveContent: Object,
    currentToken: String,
  },
  data() {
    return {
      value: null,
      show: false,

      page: "",
    };
  },
  computed: {
    videoId() {
      return this.preset.id;
    },
    seconds: {
      get() {
        if (!this.saveContent.saveData.seconds || !this.saveContent.saveData.duration) {
          return 0;
        }

        // eslint-disable-next-line prettier/prettier
        if ( Math.round( this.saveContent.saveData.seconds)  ===   Math.round(this.saveContent.saveData.duration) ) {
          return 0;
        }

        return Math.min(Math.max(this.saveContent.saveData.seconds, 0), Math.floor(this.saveContent.saveData.duration));
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.seconds = value;
      },
    },
    duration: {
      get() {
        return this.saveContent.saveData.duration || 0;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.duration = value;
      },
    },
  },
  methods: {
    onTimeTick(data) {
      //seconds/percent/duration
      this.seconds = data.seconds;
      this.duration = data.duration;
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.progress = data.progress;
      this.$emit("save-data", this.saveContent);
    },
  },
  created() {
    this.show = true;

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      this.page = this.saveContent.progress === 100 ? "final" : "video";
    }, 500);
  },
  destroyed() {
    // this.vimeoPlayer.off("timeupdate", this.onTimeUpdate);
  },
};
</script>
