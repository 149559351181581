<template>
  <div class="d-lg-none d-md-block d-sm-block">
    <b-navbar toggleable="lg" type="light" variant="white" class="rounded mb-4">
      <b-navbar-brand href="#">
        <button v-if="saveContent.progress === 100" @click="$emit('show-certificate')" class="btn btn-sm btn-success font-weight-bold px-3 px-xxl-5 lead">
          {{ $t("GENERAL.CERTIFICATE") }}
        </button>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="bg-primary">
        <div class="primary py-2 font-weight-bolder text-white">
          <i class="fa fa-bars text-white mr-2"></i>
          {{ $t("GENERAL.CONTENT_MENU") }}
        </div>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!--begin::Navigation-->
        <mixed-course-menu
          :preset="preset"
          :save-content="saveContent"
          :update-screen="updateScreen"
          :current-token="currentToken"
          :module-progress="moduleProgress"
          :item-progress="itemProgress"
          @item-selected="itemSelected"
        >
        </mixed-course-menu>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import MixedCourseMenu from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseMenu/MixedCourseMenu";
export default {
  name: "mixed-course-menu-popup",
  props: {
    preset: Object,

    saveContent: Object,
    currentToken: String,
    moduleProgress: Function,
    itemProgress: Function,
    updateScreen: Number,
  },
  components: { MixedCourseMenu },
  data() {
    return { showing: false };
  },

  methods: {
    itemSelected(context) {
      if (context.item) {
        this.$root.$emit("bv::toggle::collapse", "nav-collapse");
      }
      this.$emit("item-selected", context);
    },
  },
  mounted() {},
};
</script>
