<template>
  <div class="LaRange mt-20 rounded border-l border-blue-400">
    <b-row class="mb-0" style="min-height: 58px !important">
      <b-col class="rounded p-2 d-flex w-full mb-0">
        <div class="flex float-right">
          <i :id="'question_' + questionNumber" class="fa fa-check fa-2x mx-2" :class="{ 'text-success': answer !== null, 'text-light': answer == null }"></i>
        </div>
        <span class="h6 mt-2 ml-2 flex mb-0">{{ questionNumber }}- {{ text }} {{ displayAnswer }}</span>
      </b-col>
    </b-row>

    <b-form-group v-slot="{ ariaDescribedby }" class="d-md-none">
      <b-form-radio-group
        size="lg"
        v-model="answer"
        class="lead align-center"
        :options="options"
        button-variant="outline-primary"
        :aria-describedby="ariaDescribedby"
        :name="'radio' + questionNumber"
        stacked
        buttons
        @change="radioChanged"
      ></b-form-radio-group>
    </b-form-group>

    <b-row class="mb-4 p-2 rounded text-primary d-none d-md-flex" align-h="center">
      <b-col class="">
        <button
          class="btn btn-sm w-100 text-center p-1 m-2"
          style="min-height: 40px"
          :class="{
            'btn-primary': answer === 1,
            'btn-outline-primary': answer !== 1,
          }"
          @click="setAnswer(1)"
          v-html="$t('GENERAL.COMPLETELY_DISAGREE')"
        ></button>
      </b-col>
      <b-col class="">
        <button
          class="btn btn-sm w-100 text-center p-1 m-2"
          style="min-height: 40px"
          :class="{
            'btn-primary': answer === 25,
            'btn-outline-primary': answer !== 25,
          }"
          @click="setAnswer(25)"
          v-html="$t('GENERAL.PARTIALLY_DISAGREE')"
        ></button>
      </b-col>
      <b-col class="">
        <button
          class="btn btn-sm w-100 text-center p-1 m-2"
          style="min-height: 40px"
          :class="{
            'btn-primary': answer === 50,
            'btn-outline-primary': answer !== 50,
          }"
          @click="setAnswer(50)"
          v-html="$t('GENERAL.NEUTRAL')"
        ></button>
      </b-col>
      <b-col class="">
        <button
          class="btn btn-sm w-100 text-center p-1 m-2"
          style="min-height: 40px"
          :class="{
            'btn-primary': answer === 75,
            'btn-outline-primary': answer !== 75,
          }"
          @click="setAnswer(75)"
          v-html="$t('GENERAL.PARTIALLY_AGREE')"
        ></button>
      </b-col>
      <b-col class="">
        <button
          class="btn btn-sm w-100 text-center p-1 m-2"
          style="min-height: 40px"
          :class="{
            'btn-primary': answer === 100,
            'btn-outline-primary': answer !== 100,
          }"
          @click="setAnswer(100)"
          v-html="$t('GENERAL.COMPLETELY_AGREE')"
        ></button>
      </b-col>
    </b-row>

    <b-row> </b-row>
    <hr />
  </div>
</template>

<script>
export default {
  name: "la-range",
  props: {
    preset: Object,
    value: Object,
  },
  data() {
    return {
      options: [
        { text: this.$t("GENERAL.COMPLETELY_DISAGREE"), value: 1 },
        { text: this.$t("GENERAL.PARTIALLY_DISAGREE"), value: 25 },
        { text: this.$t("GENERAL.NEUTRAL"), value: 50 },
        { text: this.$t("GENERAL.PARTIALLY_AGREE"), value: 75 },
        { text: this.$t("GENERAL.COMPLETELY_AGREE"), value: 100 },
      ],
    };
  },
  computed: {
    text() {
      return this.preset.text;
    },

    questionNumber() {
      return this.preset.questionNumber;
    },
    answer: {
      get() {
        return this.value.answer <= 0 ? null : this.value.answer;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.value.answer = value;
      },
    },
    displayAnswer: {
      get() {
        return process.env.VUE_APP_ENV === "development" ? this.answer : "";
      },
    },
  },
  methods: {
    mousedown() {
      if (this.answer == null) this.answer = 50;
    },
    setAnswer(value) {
      this.answer = value;
      this.$scrollTo("#question_" + this.questionNumber, 500, { easing: "ease-in-out", offset: -200 });
    },
    radioChanged() {
      this.$scrollTo("#question_" + (this.questionNumber + 1), 500, { easing: "ease-in-out", offset: -200 });
    },
  },
  mounted() {
    // console.log(process.env.VUE_APP_ENV);
  },
};
</script>
<style scoped lang="scss">
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 14px;
  background: #e5f2ff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  opacity: 0.9;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

slider-disabled {
}

.slider-h {
  height: 4px;
}
.slider-margin {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 2px solid black;
}
.slider-margin-r {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 2px solid black;
}
.slider-top-left {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
}

.slider-top-right {
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.slider:hover {
  opacity: 1;
}

.sliderT::-webkit-slider-thumb {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e5f2ff;
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ff0000;
  cursor: pointer;
}

.sliderT::-moz-range-thumb {
  border: 2px solid #e5f2ff;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ff0000;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.slider::-webkit-slider-thumb {
  border: 2px solid #6288ee;
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #6993ff;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.slider::-moz-range-thumb {
  border: 2px solid #6288ee;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #6993ff;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-option {
  background: #e5f2ff;
}

.slider-disabled::-webkit-slider-thumb {
  border: 2px solid #e5f2ff !important;
  background: #999 !important;
}

.slider-disabled::-moz-range-thumb {
  border: 2px solid #e5f2ff !important;
  background: #999 !important;
}
</style>
