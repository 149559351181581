<template>
  <div class="MixedCourseMenu">
    <div class="w-100">
      <p class="font-weight-bold lead text-dark text-center mb-10">
        {{ $t("GENERAL.MENU_MODULES") | uppercase }}
      </p>
    </div>
    <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
      <div class="mb-10" v-for="(presetModule, index) in preset.treeData" :key="'static_menu_item_' + index">
        <div class="py-2 w-100 border-bottom border-bottom-primary text-left module-hover" @click="setActiveModule(presetModule.codename)">
          <span class="mr-2 m-0 px-2 py-1 badge">
            <i class="fa fa-chevron-down text-sm text-primary" v-if="currentModuleCodename === presetModule.codename"></i>
            <i class="fa fa-chevron-right" v-if="currentModuleCodename !== presetModule.codename"></i>
          </span>
          <span
            class="font-size-lg lead"
            :class="{
              'text-dark': currentModuleCodename !== presetModule.codename,
              'text-primary font-weight-bolder': currentModuleCodename === presetModule.codename,
            }"
            >{{ presetModule.name }}
          </span>
          <span class="navi-label float-right mt-1">
            <span :key="updateScreen" class="label label-inline font-weight-bolder" :class="getProgressClass(moduleProgress(presetModule.codename))"
              >{{ moduleProgress(presetModule.codename) }}%</span
            >
          </span>
        </div>
        <b-collapse :visible="currentModuleCodename === presetModule.codename">
          <div class="navi-item mb-2 ml-4" role="button" v-for="(moduleItem, contentIndex) in presetModule.items" :key="'stati-menu-subitem-' + contentIndex">
            <button
              @click="setActiveItem(presetModule.codename, moduleItem.codename)"
              :class="{
                active: currentModuleCodename === presetModule.codename && currentItemCodename === moduleItem.codename,
              }"
              class="navi-link py-4 subitem px-2 ml-0 w-100"
              style="cursor: pointer"
              data-tab="1"
              data-toggle="tab"
              role="tab"
              aria-selected="false"
            >
              <span class="navi-icon text-dark">
                <i class="fa" :class="getIcon(moduleItem.engine)"></i>
              </span>
              <span class="navi-text text-left ml-1">{{ moduleItem.name | uppercase }}</span>
              <span class="navi-label">
                <span
                  :key="updateScreen"
                  class="label label-inline font-weight-bolder"
                  :class="getProgressClass(itemProgress(presetModule.codename, moduleItem.codename))"
                  >{{ itemProgress(presetModule.codename, moduleItem.codename) }}%</span
                >
              </span>
            </button>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<style scoped>
.module-hover:hover {
  cursor: pointer;
  background-color: #fafafa;
}
.b-dropdown-text {
  padding: 0 0 0 1.25rem !important;
  border: 1px solid white;
}
</style>

<script>
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "mixed-course-menu",
  mixins: [uppercase],
  props: {
    preset: Object,

    saveContent: Object,
    currentToken: String,
    moduleProgress: Function,
    itemProgress: Function,
    updateScreen: Number,
  },
  data() {
    return {
      // currentItemCodename:
    };
  },
  computed: {
    currentModuleCodename: {
      get() {
        return this.saveContent.saveData.currentModuleCodename;
      },
    },
    currentItemCodename: {
      get() {
        return this.saveContent.saveData.currentItemCodename;
      },
    },
  },
  methods: {
    setActiveModule(codename) {
      const module = this.preset.treeData.find((module) => module.codename === codename);
      this.$emit("item-selected", { module });
    },
    setActiveItem(moduleCodename, itemCodename) {
      const module = this.preset.treeData.find((module) => module.codename === moduleCodename);

      const item = module.items.find((item) => item.codename === itemCodename);
      this.$emit("item-selected", { module, item });
    },

    getProgressClass(progress) {
      progress = parseInt(progress);
      if (progress === 0) return "label-light-danger";
      if (progress === 100) return "label-light-success";
      return "label-light-warning";
    },
    getIcon(iconName) {
      switch (iconName) {
        case "vimeo":
          return "fa-video";
        case "pdf":
          return "fa-file-pdf";
        case "xlsx":
        case "xls":
          return "fa-file-excel";
        case "mp3":
          return "fa-volume-up";
        case "docx":
        case "doc":
          return "fa-file-word";
        case "pptx":
          return "fa-file-powerpoint";
        default:
          return "fa-file";
      }
    },
  },
  mounted() {},
};
</script>
