var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"GripEngine"},[(_vm.saveContent.progress !== 100)?_c('b-card',{staticClass:"mb-2",attrs:{"img-alt":"Image","img-top":"","tag":"article","body-class":'p-1'}},[_c('b-card-body',{staticClass:"m-1",attrs:{"body-class":"p-4"}},[_c('h2',{staticClass:"text-center mb-10 mt-5 text-dark"},[_vm._v(" "+_vm._s(_vm.preset.texts.name)+" ")]),_c('hr',{staticClass:"my-10"}),_c('div',{staticClass:"text-dark",domProps:{"innerHTML":_vm._s(_vm.preset.texts.info)}}),_c('hr',{staticClass:"my-10"}),_vm._l((this.preset.questions),function(item,index){return _c('div',{key:index + 'c'},[_c('h1',{staticClass:"text-primary text-center mb-5 text-dark"},[_vm._v(_vm._s(_vm.preset.texts.scenario)+" "+_vm._s(index + 1))]),_c('b-row',{staticClass:"mb-4 rounded text-primary",attrs:{"cols":"2","cols-sm":"2"}},[_c('b-col',{staticClass:"p-3 rounded font-size-lg border border-4",class:_vm.textClass(index, 'left')},[_c('p',{staticClass:"font-weight-bolder text-center lead"},[_vm._v(" "+_vm._s(_vm.preset.texts.case_a)+" ")]),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item["left"])+" ")])]),_c('b-col',{staticClass:"p-3 rounded font-size-lg border border-4",class:_vm.textClass(index, 'right')},[_c('p',{staticClass:"font-weight-bolder text-center lead"},[_vm._v(" "+_vm._s(_vm.preset.texts.case_b)+" ")]),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item["right"])+" ")])])],1),_c('b-row',{staticClass:"mb-4 p-2 rounded text-primary",attrs:{"cols":"4"}},[_c('b-col',{staticClass:"d-flex p-1"},[_c('button',{staticClass:"btn w-100 text-center p-0",class:{
                'btn-primary': _vm.answer(index) === 1,
                'btn-outline-primary': _vm.answer(index) !== 1,
              },staticStyle:{"min-height":"50px"},domProps:{"innerHTML":_vm._s(_vm.preset.texts.always_a)},on:{"click":function($event){return _vm.setValue(index, 1)}}})]),_c('b-col',{staticClass:"d-flex p-1"},[_c('button',{staticClass:"btn w-100 text-center p-1",class:{
                'btn-primary': _vm.answer(index) === 2,
                'btn-outline-primary': _vm.answer(index) !== 2,
              },staticStyle:{"min-height":"50px"},domProps:{"innerHTML":_vm._s(_vm.preset.texts.often_a)},on:{"click":function($event){return _vm.setValue(index, 2)}}})]),_c('b-col',{staticClass:"d-flex p-1"},[_c('button',{staticClass:"btn w-100 text-center p-1",class:{
                'btn-primary': _vm.answer(index) === 3,
                'btn-outline-primary': _vm.answer(index) !== 3,
              },staticStyle:{"min-height":"50px"},domProps:{"innerHTML":_vm._s(_vm.preset.texts.often_b)},on:{"click":function($event){return _vm.setValue(index, 3)}}})]),_c('b-col',{staticClass:"d-flex p-1"},[_c('button',{staticClass:"btn w-100 text-center p-1",class:{
                'btn-primary': _vm.answer(index) === 4,
                'btn-outline-primary': _vm.answer(index) !== 4,
              },staticStyle:{"min-height":"50px"},domProps:{"innerHTML":_vm._s(_vm.preset.texts.always_b)},on:{"click":function($event){return _vm.setValue(index, 4)}}})])],1),_c('hr',{staticClass:"my-10"})],1)}),_c('h3',{staticClass:"text-center mb-10 mt-5 text-warning"},[_vm._v(_vm._s(_vm.preset.texts.answered_warning)+"!")]),_c('h3',{staticClass:"text-center mb-10 mt-5 text-dark"},[_vm._v(_vm._s(_vm.preset.texts.answered)+": "+_vm._s(_vm.qtyAnswered)+"/"+_vm._s(_vm.preset.questions.length))])],2)],1):_vm._e(),(_vm.saveContent.progress === 100 && _vm.currentToken !== '')?_c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body text-dark"},[_c('run-content-last-page',{attrs:{"engine":_vm.saveContent.engine,"save-content":_vm.saveContent,"currentToken":_vm.currentToken,"content-codename":_vm.preset.codename,"text":"GENERAL.CONTENT_LAST_PAGE"}})],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }