<template>
  <div class="MixedCourseItemVimeo">
    <la-vimeo-player :video-id="itemPreset.resource" :show="true" :seconds="seconds" @time-tick="onTimeTick" @end-tick="onEndTick"> </la-vimeo-player>
  </div>
</template>

<script>
import LaVimeoPlayer from "@/view/pages/content-components/LaVimeoPlayer/LaVimeoPlayer";
export default {
  name: "mixed-course-item-vimeo",
  components: { LaVimeoPlayer },
  props: {
    itemPreset: {
      type: Object,
      required: true,
    },

    itemSaveData: {
      type: Object,
      required: true,
    },
    contentEngine: {
      type: String,
      required: true,
    },
    currentToken: {
      type: String,
      required: true,
    },
    contentCodename: {
      type: String,
      required: true,
    },
  },
  computed: {
    seconds() {
      if (!this.itemSaveData.seconds || !this.itemSaveData.duration) {
        return 0;
      }

      // eslint-disable-next-line prettier/prettier
      if ( Math.round( this.itemSaveData.seconds)  ===   Math.round(this.itemSaveData.duration) ) {
        return 0;
      }

      return Math.min(Math.max(this.itemSaveData.seconds, 0), Math.floor(this.itemSaveData.duration));
    },
  },
  methods: {
    onEndTick(context) {
      this.$emit("finished", context);
    },
    onTimeTick(context) {
      this.$emit("save-data", context);
    },
  },
  mounted() {},
};
</script>
