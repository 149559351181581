<template>
  <div class="LaVimeoPlayer" v-if="show">
    <iframe
      ref="laVimeoPlayerFrame"
      id="laVimeoPlayerFrame"
      :src="`https://player.vimeo.com/video/${videoId}`"
      width="100%"
      height="400"
      class="card border-0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  name: "la-vimeo-player",
  props: {
    timeTicker: {
      type: Number,
      default: 10000,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    videoId: {
      type: String,
      required: true,
    },
    seconds: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      vimeoPlayer: null,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show) {
          setTimeout(() => {
            this.vimeoPlayer = new Player(document.getElementById("laVimeoPlayerFrame"));
            this.vimeoPlayer.setCurrentTime(this.seconds);
            this.vimeoPlayer.on("timeupdate", this.onTimeUpdate);
            this.vimeoPlayer.on("ended", this.onEnd);
          }, 200);
        }
      },
    },
  },
  methods: {
    onEnd(progress) {
      progress.progress = 100;
      this.$emit("end-tick", {
        progress: 100,
        seconds: progress.seconds,
        duration: progress.duration,
      });
    },
    onTimeUpdate(progress) {
      if (!this.saveThrottle) {
        this.saveThrottle = true;
        this.$emit("time-tick", {
          seconds: progress.seconds,
          duration: progress.duration,
          // eslint-disable-next-line prettier/prettier
          progress : Math.min(Math.max(Math.round(((progress.seconds + 1) /progress.duration ) * 100), 0), 100)
        });
        setTimeout(() => {
          this.saveThrottle = false;
        }, this.timeTicker);
      }
    },
  },
};
</script>
