<template>
  <div class="Big5Intro">
    <h1 class="display-4">{{ presetTexts.intro_welcome }}!</h1>
    <p class="lead" v-html="presetTexts.intro_introduction"></p>

    <h2 class="mt-20" v-html="presetTexts.intro_how_to_title"></h2>
    <p class="lead" v-html="presetTexts.intro_how_to_1"></p>
    <p class="lead" v-html="presetTexts.intro_how_to_2"></p>

    <h2 v-if="false" class="mt-10" v-html="presetTexts.intro_title"></h2>
    <p v-if="false" class="lead" v-html="presetTexts.intro_2"></p>
    <div v-if="false" class="card p-1 my-10 bg-primary">
      <img style="" class="w-100 shadow-xs" :src="generateUrl('select_input')" />
    </div>

    <p v-if="false" class="lead" v-html="presetTexts.intro_3"></p>
    <div v-if="false" class="card p-1 my-10 bg-primary">
      <img style="" class="w-100 shadow-xs" :src="generateUrl('slider_input')" />
    </div>

    <h2 class="mt-10" v-html="presetTexts.intro_report_title"></h2>
    <p class="lead" v-html="presetTexts.intro_report_1"></p>
  </div>
</template>

<script>
export default {
  name: "big5-intro",

  methods: {
    generateUrl(obj) {
      return `${process.env.VUE_APP_API_URL}/storage/pictures/contents/big5/${obj}.gif`;
    },
  },
  props: {
    presetTexts: Object,
  },
};
</script>
