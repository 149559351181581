<template>
  <div class="MixedCourseItemDownload">
    <pre v-text="$attrs" />
    <pre v-text="value" />
  </div>
</template>

<script>
export default {
  name: "mixed-course-item-download",
  props: {
    value: Object,
  },
  mounted() {},
};
</script>
