<template>
  <div class="Big5Engine text-dark" v-if="this.show">
    <b-card class="shadow-xs" header-tag="header" footer-tag="footer" border-variant="primary" header-border-variant="primary">
      <template #header v-if="intro !== 0 && page < tabsQuantity">
        <h3 class="mb-0" v-if="false">{{ preset.preset_texts.name }}</h3>
        <b-progress :max="tabsQuantity" height="1.2rem" class="mt-4">
          <b-progress-bar :value="page + 1">
            <span
              ><strong>{{ page + 1 }} / {{ tabsQuantity }}</strong></span
            >
          </b-progress-bar>
        </b-progress>
      </template>
      <div v-if="intro === 0">
        <big5-intro :preset-texts="preset.preset_texts"></big5-intro>
      </div>

      <div class="row" v-if="page === 0 && intro !== 0" id="start">
        <div class="col-6">
          <div class="form-group" v-if="false">
            <label>{{ preset.preset_texts.age_range }}</label>
            <select class="form-control form-control-lg" v-model="age">
              <option value=""></option>
              <option value="y1020">
                {{ preset.preset_texts.y1020 }}
              </option>
              <option value="y2140">
                {{ preset.preset_texts.y2140 }}
              </option>
              <option value="y4160">
                {{ preset.preset_texts.y4160 }}
              </option>
              <option value="y6195">
                {{ preset.preset_texts.y6195 }}
              </option>
            </select>
          </div>
          <label class="font-weight-bold" style="font-size: 16px">{{ preset.preset_texts.age_range }}:</label>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              size="lg"
              v-model="age"
              class="lead align-center"
              :options="ages"
              button-variant="outline-primary"
              :aria-describedby="ariaDescribedby"
              :name="'radioAge'"
              stacked
              @change="onRadioChange"
              buttons
            ></b-form-radio-group>
          </b-form-group>
          <div class="form-group" v-if="false">
            <label class="font-weight-bold" style="font-size: 16px">{{ preset.preset_texts.age_range }}</label>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="age" id="y1020" value="y1020" v-model="age" />
              <label class="form-check-label" for="y1020" style="font-size: 16px"> {{ preset.preset_texts.y1020 }} </label>
            </div>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="age" id="y2140" value="y2140" v-model="age" />
              <label class="form-check-label" for="y2140" style="font-size: 16px"> {{ preset.preset_texts.y2140 }} </label>
            </div>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="age" id="y4160" value="y4160" v-model="age" />
              <label class="form-check-label" for="y4160" style="font-size: 16px"> {{ preset.preset_texts.y4160 }} </label>
            </div>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="age" id="y6195" value="y6195" v-model="age" />
              <label class="form-check-label" for="y6195" style="font-size: 16px"> {{ preset.preset_texts.y6195 }} </label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <label class="font-weight-bold" style="font-size: 16px">{{ preset.preset_texts.gender }}:</label>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              size="lg"
              v-model="sex"
              class="lead align-center"
              :options="sexes"
              button-variant="outline-primary"
              :aria-describedby="ariaDescribedby"
              :name="'radioSex'"
              @change="onRadioChange"
              stacked
              buttons
            ></b-form-radio-group>
          </b-form-group>

          <div class="form-group" v-if="false">
            <label>{{ preset.preset_texts.gender }}</label>
            <select class="form-control form-control-lg" v-model="sex">
              <option value=""></option>
              <option value="m">
                {{ preset.preset_texts.male }}
              </option>
              <option value="f">
                {{ preset.preset_texts.female }}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="false">
            <label class="font-weight-bold" style="font-size: 16px">{{ preset.preset_texts.gender }}</label>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="sex" id="male" value="m" v-model="sex" />
              <label class="form-check-label" for="male" style="font-size: 16px"> {{ preset.preset_texts.male }} </label>
            </div>
            <div class="form-check">
              <input class="form-check-input mt-2" type="radio" name="sex" id="female" value="f" v-model="sex" />
              <label class="form-check-label" for="female" style="font-size: 16px"> {{ preset.preset_texts.female }} </label>
            </div>
          </div>
        </div>
      </div>

      <div v-if="intro !== 0 && sex !== '' && age !== ''" class="fade-in-up">
        <la-range v-for="(qPreset, index) in currentQuestions" :key="index" v-model="answers[page * questionsPerTab + index]" :preset="qPreset"></la-range>
      </div>

      <div v-if="saveContent.progress === 100 && page === tabsQuantity">
        <run-content-last-page
          :engine="saveContent.engine"
          :save-content="saveContent"
          :currentToken="currentToken"
          :content-codename="preset.codename"
          text="GENERAL.CONTENT_LAST_PAGE"
        ></run-content-last-page>
      </div>

      <template #footer v-if="page !== tabsQuantity">
        <b-button @click="gotoPage(page - 1)" variant="primary" v-if="intro > 0">{{ $t("GENERAL.PREVIOUS") }}</b-button>
        <b-button @click="gotoPage(page + 1)" variant="primary" class="float-right">{{ $t("GENERAL.NEXT") }}</b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import LaRange from "@/view/pages/content-engines/Big5Engine/components/LaRange/LaRange";
import TheToaster from "@/core/services/the-toaster";
import Big5Intro from "@/view/pages/content-engines/Big5Engine/components/Big5Intro/Big5Intro";
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";

export default {
  name: "big5-engine",
  components: { RunContentLastPage, Big5Intro, LaRange },
  props: {
    preset: Object,
    saveContent: Object,
    currentToken: String,
  },
  data() {
    return {
      value: null,
      show: false,
      saveFn: null,
      ages: [
        { text: this.preset.preset_texts.y1020, value: "y1020" },
        { text: this.preset.preset_texts.y2140, value: "y2140" },
        { text: this.preset.preset_texts.y4160, value: "y4160" },
        { text: this.preset.preset_texts.y6195, value: "y6195" },
      ],
      sexes: [
        { text: this.preset.preset_texts.male, value: "m" },
        { text: this.preset.preset_texts.female, value: "f" },
      ],
    };
  },
  watch: {},
  methods: {
    keepSaving() {
      if (this.saveContent.progress < 100) {
        this.saveFn = setTimeout(() => {
          if (this.intro !== 0) {
            this.saveData();
          } else {
            clearTimeout(this.saveFn);
          }
          this.keepSaving();
        }, 30000);
      }
    },
    onRadioChange() {
      if (this.age && this.sex) {
        this.$scrollTo("#start", 500, { easing: "ease-in-out", offset: -200 });
      }
    },
    saveData() {
      clearTimeout(this.saveFn);
      let res = this.lastPageEnabled();
      // eslint-disable-next-line prettier/prettier,vue/no-mutating-props
      this.saveContent.progress = Math.min(Math.max(Math.floor((res.question / this.answers.length) * 100), 0), 100);
      this.$emit("save-data", this.saveContent);
    },
    gotoPage(page) {
      if (page === -1 && this.saveContent.progress < 100) {
        page = Math.max(page, 0);
        this.intro = 0;

        return;
      }
      if (this.intro === 0) {
        this.intro++;
        this.saveData();
        this.keepSaving();
        return;
      }
      let res = this.lastPageEnabled();

      page = Math.max(page, 0);

      if (res.page >= page) {
        this.page = page;
        this.saveData();
        setTimeout(() => {
          this.keepSaving();
          window.scrollTo(0, 0);
        }, 30);
      } else {
        TheToaster.error(this.preset.preset_texts.fill_fields_before_next_step);
        this.$scrollTo("#question_" + (res.question + 1), 600, { easing: "ease-in-out", offset: -250 });
      }
    },
    lastPageEnabled() {
      let res = {
        page: 0,
        question: 0,
      };

      if (this.age === "" || this.sex === "") {
        return res;
      }
      this.answers.map((answerObj, index) => {
        if (answerObj.answer === null || answerObj.answer === undefined) {
          answerObj.answer = 0;
        }
      });

      for (let tab = 0; tab < this.tabsQuantity; tab++) {
        res.page = tab;
        // console.log(i * this.questionsPerTab, i * this.questionsPerTab + this.questionsPerTab, this.questionsPerTab);
        for (let j = tab * this.questionsPerTab; j < tab * this.questionsPerTab + this.questionsPerTab; j++) {
          res.question = j;
          if (this.answers[j].answer === 0) {
            return res;
          }
        }
      }
      res.question++;
      res.page++;
      return res;
    },
  },
  computed: {
    intro: {
      get() {
        return this.saveContent.saveData.intro || 0;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.intro = value;
      },
    },
    sex: {
      get() {
        return this.saveContent.saveData.sex || "";
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.sex = value;
      },
    },
    age: {
      get() {
        return this.saveContent.saveData.age || "";
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.age = value;
      },
    },
    page: {
      get() {
        return this.saveContent.saveData.page || 0;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.page = value;
      },
    },
    tabsQuantity() {
      return this.preset.tabsQuantity || 0;
    },
    questionsPerTab() {
      return this.preset.questionsPerTab || 0;
    },
    answers() {
      return this.saveContent.saveData.answers || [];
    },
    currentQuestions() {
      return this.preset.questions.slice(this.page * this.questionsPerTab, this.page * this.questionsPerTab + this.questionsPerTab);
    },
  },
  beforeDestroy() {
    clearTimeout(this.saveFn);
  },
  beforeMount() {
    let temp = this.lastPageEnabled();
    // eslint-disable-next-line vue/no-mutating-props
    this.page = temp.page;
  },
  mounted() {
    this.keepSaving();
    // for (let i = 0; i < this.answers.length - 8; i++) {
    //   this.answers[i].answer = 10;
    // }
    this.show = true;
  },
};
</script>
