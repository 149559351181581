<template>
  <div class="MixedCourseEngine">
    <!--begin::Navigation-->
    <mixed-course-menu-popup
      :preset="preset"
      :save-content="saveContent"
      :update-screen="totalProgressRefresher"
      :current-token="currentToken"
      :module-progress="moduleProgress"
      :item-progress="itemProgress"
      @item-selected="itemSelected"
      @show-certificate="page = 'certificate'"
    >
    </mixed-course-menu-popup>
    <div class="card" v-if="page === 'certificate'">
      <div class="card-body text-dark">
        <run-content-last-page
          :engine="saveContent.engine"
          :save-content="saveContent"
          :currentToken="currentToken"
          :content-codename="preset.codename"
          text="GENERAL.CONTENT_LAST_PAGE_VIDEO"
          @replay="page = 'course'"
        ></run-content-last-page>
      </div>
    </div>

    <div class="d-flex" v-if="page === 'course'">
      <div class="flex-row-auto min-w-300px d-none d-lg-block d-xl-block d-xxl-block" id="kt_profile_aside">
        <div class="card card-custom card-stretch mr-5">
          <div class="card-body pt-4">
            <div class="d-flex justify-content-end"></div>
            <div class="d-flex align-items-center mt-5">
              <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center" v-if="false">
                <div
                  class="symbol-label bg-contain"
                  :style="{
                    backgroundImage: `url(media/contents/${contentCodename}.jpg)`,
                  }"
                ></div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div v-if="false">
                <h3 class="font-weight-bolder text-dark-75">
                  {{ preset.preset_texts.name | uppercase }}
                </h3>
                <div class="text-muted">
                  {{ preset.preset_texts.subtitle || "" }}
                </div>
                <p class="font-weight-bolder text-dark-75 mt-4">
                  {{ $t("GENERAL.OVERALL_PROGRESS") }}:
                  <span class="label label-inline font-weight-bolder" :class="getProgressClass(saveContent.progress)" :key="totalProgressRefresher"
                    >{{ saveContent.progress }}%</span
                  >
                </p>
              </div>

              <div class="w-100 mb-2 ml-4 text-dark lead" role="button">
                <span class="navi-icon text-dark">
                  <i class="fa fa-percent text-primary"></i>
                </span>
                <span class="navi-text text-left ml-1"> {{ $t("GENERAL.OVERALL_PROGRESS") | uppercase }}:</span>
                <div class="navi-label float-right">
                  <span :key="totalProgressRefresher" class="label label-inline font-weight-bolder" :class="getProgressClass(saveContent.progress)"
                    >{{ saveContent.progress }}%</span
                  >
                </div>
              </div>
            </div>
            <div class="my-4 w-100" v-if="saveContent.progress === 100">
              <div>
                <button
                  v-if="saveContent.progress === 100"
                  @click="page = 'certificate'"
                  class="btn btn-sm btn-success font-weight-bold px-3 px-xxl-5 lead w-100"
                >
                  {{ $t("GENERAL.CERTIFICATE") }}
                </button>
              </div>
            </div>
            <hr />

            <!--begin::Navigation-->
            <mixed-course-menu
              :preset="preset"
              :save-content="saveContent"
              :update-screen="totalProgressRefresher"
              :current-token="currentToken"
              :module-progress="moduleProgress"
              :item-progress="itemProgress"
              @item-selected="itemSelected"
            >
            </mixed-course-menu>
            <!--end::Navigation-->

            <!--begin::Nav-->
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="card card-custom gutter-b w-100" v-if="currentItemPreset">
          <!--begin::Header-->
          <div class="card-header border-1">
            <h3 class="card-title text-dark h2">
              {{ currentItemPreset.name | uppercase }}
            </h3>
          </div>
          <div class="card-body d-flex flex-column">
            <div class="flex-grow-1">
              <b-row class="justify-content-md-center" v-if="showLoading">
                <b-col cols="12" md="auto">
                  <b-spinner class="text-primary"></b-spinner>
                </b-col>
              </b-row>

              <component
                v-if="currentItemPreset && !showLoading"
                v-bind:is="itemComponentFromEngine"
                :content-engine="preset.engine"
                :content-codename="contentCodename"
                :current-token="currentToken"
                :item-preset="currentItemPreset"
                :item-save-data="getItemSaveData(currentModule.codename, currentItemPreset.codename)"
                @save-data="saveItemProgress"
                @finished="saveItemProgress"
              ></component>

              <div class="my-4 w-100 d-lg-none d-md-block d-sm-block" v-if="saveContent.progress === 100">
                <div>
                  <button
                    v-if="saveContent.progress === 100"
                    @click="page = 'certificate'"
                    class="btn btn-sm btn-success font-weight-bold px-3 px-xxl-5 lead float-right"
                  >
                    {{ $t("GENERAL.CERTIFICATE") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b example example-compact mt-10" v-if="info.length > 0">
          <div class="card-header">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t("GENERAL.INFO") | uppercase }}</h3>
            </div>
          </div>
          <div class="card-body text-dark lead">{{ currentItemPreset.info || "" }}</div>
          <!---->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
<script>
import MixedCourseMenu from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseMenu/MixedCourseMenu";
import MixedCourseItemVimeo from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseItemVimeo/MixedCourseItemVimeo";
import MixedCourseItemPdf from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseItemPdf/MixedCourseItemPdf";
import MixedCourseItemDownload from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseItemDownload/MixedCourseItemDownload";
import MixedCourseMenuPopup from "@/view/pages/content-engines/MixedCourseEngine/components/MixedCourseMenuPopup/MixedCourseMenuPopup";
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "mixed-course-engine",
  mixins: [uppercase],
  components: {
    RunContentLastPage,
    MixedCourseMenuPopup,
    MixedCourseMenu,
    MixedCourseItemVimeo,
    MixedCourseItemPdf,
    MixedCourseItemDownload,
  },
  props: {
    preset: Object,
    saveContent: Object,
    currentToken: String,
  },
  data() {
    return {
      totalProgressRefresher: 0,
      showLoading: true,
      page: "course",
      currentModule: null,
      currentItemPreset: null,
    };
  },

  computed: {
    info: {
      get() {
        if (!this.currentItemPreset) return "";
        return this.currentItemPreset.info || "";
      },
    },

    contentCodename: {
      get() {
        return this.preset.codename;
      },
    },
    moduleProgress: {
      get() {
        let self = this;
        return (module) => {
          module = module.toLowerCase();
          let moduleObject = self.preset.treeData.find((item) => item.codename.toLowerCase() === module);
          if (!moduleObject) {
            return 0;
          }
          const itemsTotal = moduleObject?.items.length || 0;
          const progressSum = moduleObject?.items.reduce((theSum, item) => {
            return theSum + parseFloat(self.itemProgress(module, item.codename));
          }, 0);
          return Math.ceil(progressSum / itemsTotal) || 0;
        };
      },
    },
    itemProgress: {
      get() {
        let self = this;
        return (module, item) => {
          module = module.toLowerCase();
          item = item.toLowerCase();
          const p = self.saveContent.saveData.progresses[module + "." + item]?.progress || 0;
          return Math.ceil(p);
        };
      },
    },

    itemComponentFromEngine: {
      get() {
        switch (this.currentItemPreset.engine) {
          case "vimeo":
          case "pdf":
            return "mixed-course-item-" + this.currentItemPreset.engine;
          case "xlsx":
          case "mp3":
          case "pptx":
          default:
            return "mixed-course-item-download";
        }
      },
    },
  },
  methods: {
    totalProgress() {
      const self = this;
      let tp = this.preset.treeData.reduce((theSum, module) => {
        return theSum + parseFloat(self.moduleProgress(module.codename));
      }, 0);
      return Math.ceil(tp / this.preset.treeData.length);
    },
    getProgressClass(progress) {
      progress = parseInt(progress);
      if (progress === 0) return "label-light-danger";
      if (progress === 100) return "label-light-success";
      return "label-light-warning";
    },
    getItemSaveData(module, item) {
      let path = (module + "." + item).toLowerCase();
      // eslint-disable-next-line prettier/prettier
      if (!this.saveContent.saveData.progresses[path] || Array.isArray(this.saveContent.saveData.progresses[path])) {
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.progresses[path] = {};
      }

      return this.saveContent.saveData.progresses[path];
    },

    saveItemProgress(data) {
      const itemProgress = this.itemProgress(this.currentModule.codename, this.currentItemPreset.codename);
      if (data.progress < itemProgress) {
        data.progress = itemProgress;
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.saveData.progresses[this.currentModule.codename.toLowerCase() + "." + this.currentItemPreset.codename.toLowerCase()] = data;

      // if (this.saveContent.saveData.totalProgress < 100) {
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.progress = this.totalProgress();
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.saveData.totalProgress = this.saveContent.progress;
      this.totalProgressRefresher++;
      this.$emit("save-data", this.saveContent);
      // }

      // this.saveContent.saveData.progresses();
    },
    itemSelected(context) {
      const self = this;
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.saveData.currentModuleCodename = context.module.codename;
      if (context.item) {
        this.showLoading = true;
        // eslint-disable-next-line vue/no-mutating-props
        this.saveContent.saveData.currentItemCodename = context.item.codename;
        setTimeout(function () {
          self.currentModule = context.module;
          self.currentItemPreset = context.item;
          self.$emit("save-data", this.saveContent);
          self.showLoading = false;
        }, 500);
      }
    },
  },
  beforeMount() {
    if (Array.isArray(this.saveContent.saveData.progresses)) {
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.saveData.progresses = {};
    }

    const self = this;

    let module = this.preset.treeData.find(
      // eslint-disable-next-line prettier/prettier
        (module) => module.codename ===  self.saveContent.saveData.currentModuleCodename
    );
    if (!module) {
      module = this.preset.treeData[0];
    }
    let item = module.items.find(
      // eslint-disable-next-line prettier/prettier
        (item) => item.codename ===  self.saveContent.saveData.currentItemCodename
    );
    if (!item) {
      item = module.items[0];
    }
    this.itemSelected({ module, item });
  },
  mounted() {},
};
</script>
