<template>
  <div class="GripEngine">
    <b-card img-alt="Image" img-top v-if="saveContent.progress !== 100" tag="article" class="mb-2" :body-class="'p-1'">
      <b-card-body class="m-1" body-class="p-4">
        <h2 class="text-center mb-10 mt-5 text-dark">
          {{ preset.texts.name }}
        </h2>

        <hr class="my-10" />
        <div v-html="preset.texts.info" class="text-dark" />
        <hr class="my-10" />
        <div v-for="(item, index) in this.preset.questions" :key="index + 'c'">
          <h1 class="text-primary text-center mb-5 text-dark">{{ preset.texts.scenario }} {{ index + 1 }}</h1>
          <b-row class="mb-4 rounded text-primary" cols="2" cols-sm="2">
            <b-col :class="textClass(index, 'left')" class="p-3 rounded font-size-lg border border-4">
              <p class="font-weight-bolder text-center lead">
                {{ preset.texts.case_a }}
              </p>
              <p class="text-center">
                {{ item["left"] }}
              </p>
            </b-col>

            <b-col class="p-3 rounded font-size-lg border border-4" :class="textClass(index, 'right')">
              <p class="font-weight-bolder text-center lead">
                {{ preset.texts.case_b }}
              </p>
              <p class="text-center">
                {{ item["right"] }}
              </p>
            </b-col>
          </b-row>
          <b-row class="mb-4 p-2 rounded text-primary" cols="4">
            <b-col class="d-flex p-1">
              <button
                class="btn w-100 text-center p-0"
                style="min-height: 50px"
                :class="{
                  'btn-primary': answer(index) === 1,
                  'btn-outline-primary': answer(index) !== 1,
                }"
                v-html="preset.texts.always_a"
                @click="setValue(index, 1)"
              ></button>
            </b-col>
            <b-col class="d-flex p-1">
              <button
                class="btn w-100 text-center p-1"
                style="min-height: 50px"
                :class="{
                  'btn-primary': answer(index) === 2,
                  'btn-outline-primary': answer(index) !== 2,
                }"
                @click="setValue(index, 2)"
                v-html="preset.texts.often_a"
              ></button>
            </b-col>

            <b-col class="d-flex p-1">
              <button
                class="btn w-100 text-center p-1"
                style="min-height: 50px"
                :class="{
                  'btn-primary': answer(index) === 3,
                  'btn-outline-primary': answer(index) !== 3,
                }"
                @click="setValue(index, 3)"
                v-html="preset.texts.often_b"
              ></button>
            </b-col>
            <b-col class="d-flex p-1">
              <button
                class="btn w-100 text-center p-1"
                style="min-height: 50px"
                :class="{
                  'btn-primary': answer(index) === 4,
                  'btn-outline-primary': answer(index) !== 4,
                }"
                @click="setValue(index, 4)"
                v-html="preset.texts.always_b"
              ></button>
            </b-col>
          </b-row>
          <hr class="my-10" />
        </div>
        <h3 class="text-center mb-10 mt-5 text-warning">{{ preset.texts.answered_warning }}!</h3>
        <h3 class="text-center mb-10 mt-5 text-dark">{{ preset.texts.answered }}: {{ qtyAnswered }}/{{ preset.questions.length }}</h3>
      </b-card-body>
    </b-card>

    <div v-if="saveContent.progress === 100 && currentToken !== ''">
      <div class="card">
        <div class="card-body text-dark">
          <run-content-last-page
            :engine="saveContent.engine"
            :save-content="saveContent"
            :currentToken="currentToken"
            :content-codename="preset.codename"
            text="GENERAL.CONTENT_LAST_PAGE"
          ></run-content-last-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RunContentLastPage from "@/view/pages/content-components/RunContentLastPage/RunContentLastPage";

export default {
  name: "grip-engine",
  components: { RunContentLastPage },
  props: {
    preset: Object,
    saveContent: Object,
    currentToken: String,
  },
  data() {
    return {};
  },
  computed: {
    answers() {
      return this.saveContent.saveData.answers;
    },
    qtyAnswered() {
      return this.answers.reduce((result, item) => {
        if (item.r !== 0) result++;
        return result;
      }, 0);
    },
  },
  methods: {
    textClass(line, side) {
      switch (side) {
        case "left":
          switch (this.answer(line)) {
            case 1:
              return "border-light-success bg-success text-success text-light";
            case 2:
              return "border-success bg-light-success  text-dark";
            default:
              return "border-white  text-dark";
          }
        case "right":
          switch (this.answer(line)) {
            case 4:
              return "border-light-success bg-success text-success text-light";
            case 3:
              return "border-success bg-light-success  text-dark";
            default:
              return " border-white  text-dark";
          }
      }
    },

    answer(line) {
      return this.answers[line]?.r ?? 0;
    },
    setValue(line, value) {
      this.answers[line].r = value;
      // eslint-disable-next-line vue/no-mutating-props
      this.saveContent.progress = Math.ceil((this.qtyAnswered / this.answers.length) * 100);
      if (this.saveContent.progress === 100) {
        this.$emit("save-data", this.saveContent);
      }
    },
    keepSaving() {
      if (this.saveContent.progress < 100) {
        this.saveFn = setTimeout(() => {
          if (this.intro !== 0) {
            this.saveData();
          } else {
            clearTimeout(this.saveFn);
          }
          this.keepSaving();
        }, 30000);
      }
    },
    saveData() {
      clearTimeout(this.saveFn);
      // let res = this.lastPageEnabled();
      // eslint-disable-next-line prettier/prettier,vue/no-mutating-props
      this.saveContent.progress = Math.ceil((this.qtyAnswered / this.answers.length) * 100);
      this.$emit("save-data", this.saveContent);
    },
  },
  mounted() {
    try {
      fbq("track", "ViewContent");
    } catch (e) {}
    this.keepSaving();
    // for (let i = 0; i < this.answers.length - 8; i++) {
    //   this.answers[i].answer = 10;
    // }
    this.show = true;
  },
};
</script>
